import categories from '/static/data/categories.json';
import products from '/static/data/products.json';
import {CategoriesModel, ProductModel} from "./categories.model";

export const minDeliveryProductPrice = 150;

export enum SubCategoriesEntries {
    SASHIMI = 'SASHIMI',
    NIGIRI = 'NIGIRI',
    HOSOMAKI = 'HOSOMAKI',
    URAMAKI = 'URAMAKI',
    FUTOMAKI = 'FUTOMAKI',
    SETS = 'SETS',
    CHIRASHI = 'CHIRASHI',
    KIDS = 'KIDS',
    DRINKS = 'DRINKS',
    WEGE = 'WEGE',
    LUNCH = 'LUNCH',
    VOUCHER = 'VOUCHER',
    SETS_SPECIAL = 'SETS_SPECIAL'
}

const allProducts: ProductModel[] = products;

export const categoriesData: CategoriesModel[] = categories;

export const getCategoryInfoByCategory = (category: SubCategoriesEntries) => {
    return categoriesData.find(item => item.categoryKey === category);
}

export const getProductById = (id: string) => {
    return allProducts.find(item => item.id === id);
}

export const getProductsByCategory = (category: SubCategoriesEntries) => {
    return allProducts.filter(item => item.category.includes(category));
}

export const getProductByUrlId = (categoryType: SubCategoriesEntries, name: string) => {
    const products = getProductsByCategory(categoryType);

    return products.find(item => {
        const urlSegments = item.url.split('/');

        if (!urlSegments.length) {
            return null;
        }

        const lastEntry = urlSegments[urlSegments.length - 1];

        return lastEntry === name;
    });
}